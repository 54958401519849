import React, { useState, useEffect } from "react";

import Logo from "../../assets/images/2024/logo.svg";
import MobileLogo from "../../assets/images/header/mobilelogo.svg";

import bars from "../../assets/images/icons/bars-solid.svg";
import styled from "styled-components";

import MenuButton from "../button/MenuButton";
import { Link } from "gatsby";
import DesktopMenu from "../DesktopMenu/DesktopMenu";

const HeaderWrapStyles = styled.div`
  /* width: 200px; */
  z-index: 100;

  /* border-radius: 20px; */
  top: 16px;
  right: 16px;

  @media (min-width: 768px) {
    top: 24px;
    right: 24px;
  }
  position: fixed;
`;
const HeaderStyles = styled.div`
  display: flex;
  height: 73px;
  left: 0;
  right: 0;
  background: var(--mfwhite);
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 10;
  position: relative;
  align-items: center;
  padding: 4px 4px;
  /* width: 200px; */
  /* border: 1px solid var(--mfblack); */
  /* border-top: 1px solid var(--mfblack); */
  border-radius: 10px;
  /* box-shadow: box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
`;

const MobileLogoStyles = styled.div`
  position: fixed;
  top: 20px;
  left: 25px;
  width: 40px;
  height: 40px;
  z-index: 100;
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`;
const MobileLogoImageStyles = styled.div`
  width: 75px;
  max-width: 75px;
`;

const LogoStyles = styled.div`
  width: 300px;
  z-index: 100;
  left: 24px;
  position: fixed;
  /* border-radius: 20px; */
  top: 15px;
  width: 200px;
  display: none;
  @media (min-width: 768px) {
    width: 300px;
    top: 20px;
    display: block;
  }
  height: auto;
  position: fixed;

  align-items: center;

  a {
    white-space: nowrap;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
  }
`;
const MenuStyles = styled.div`
  margin-left: 40px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      display: inline-block;
      /* padding-right: 16px; */
    }
  }
  display: none;
  @media (min-width: 990px) {
    display: block;
  }
`;

const RightMenuStyles = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  /* display: none; */
  @media (min-width: 720px) {
    display: flex;
    width: auto;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin: 0px 4px;
      /* padding-right: 16px; */
    }
  }
`;

export default function Header({ location, colour, setColour }) {
  //scroll pos , and set scroll of header fixed
  const [scroll, setScroll] = useState("notfixed");
  const [mainmenuOpen, setmainmenuOpen] = useState(false);

  var className = mainmenuOpen ? "open" : "closed";

  const handleMainMenuClick = (event) => {
    setmainmenuOpen(!mainmenuOpen);

    className = mainmenuOpen ? "open" : "closed";
  };

  var menuText = mainmenuOpen ? "Close" : "Menu";

  //check if home page
  const isHomepage = location.pathname === "/" ? "home" : "nothome";

  return (
    <>
      <MobileLogoStyles>
        <MobileLogoImageStyles>
          <Link to="/">
            <img src={MobileLogo} alt="" />
          </Link>
        </MobileLogoImageStyles>
      </MobileLogoStyles>
      <HeaderWrapStyles className={scroll === "fixed" ? "fixed" : "notfixed"}>
        <LogoStyles className="noslide">
          <Link to="/">
            <img src={Logo} width="100%" alt="" />
          </Link>
        </LogoStyles>
        <HeaderStyles className={isHomepage}>
          {/* <MenuStyles className="noslide">
            <ul>
              <li>
                <Button
                  bgColour="hovgreen"
                  linktext="Lineup"
                  linkto="/lineup"
                ></Button>
              </li>
              <li>
                <Button
                  bgColour="hovpink"
                  linktext="Showcases"
                  linkto="/showcases"
                ></Button>
              </li>
              <li>
                <Button
                  bgColour="hovblue"
                  linktext="News"
                  linkto="/news"
                ></Button>
              </li>

              <li>
                <Button
                  bgColour="hovyellow"
                  linktext="Info"
                  linkto="/info"
                ></Button>
              </li>
            </ul>
          </MenuStyles> */}
          <RightMenuStyles>
            {/* <Socials /> */}
            <ul>
              <li>
                <MenuButton colour="orange">
                  <Link to="https://tickets.magneticfields.in">TICKETS</Link>
                </MenuButton>
              </li>
              <li onClick={(event) => handleMainMenuClick(event)}>
                <MenuButton>
                  {menuText}
                  <img
                    width="18px"
                    src={bars}
                    alt=""
                    style={{ marginLeft: "8px" }}
                  />
                </MenuButton>
              </li>
            </ul>
          </RightMenuStyles>
        </HeaderStyles>
        <DesktopMenu
          open={className}
          mainmenuOpen={mainmenuOpen}
          setmainmenuOpen={setmainmenuOpen}
        />
      </HeaderWrapStyles>
    </>
  );
}
